<script setup>
  import { useWebStore } from '@/store/web.js'
  import { useRoute } from 'vue-router';
  const store = useWebStore();
  const route = useRoute();
  const mainContent = ref(null);
  const chosenTab = computed(() => store.chosenTab);
  const chosenLang = computed(() => store.chosenLang);

  watch([chosenTab,chosenLang], ([newVal1, newVal2]) => {
    useHeaderSet();
  });

    //SETTING LANGUAGE
  if(route.path.split('/')[1]){
    store.chosenLang = route.path.split('/')[1]
  }
  else{
    store.chosenLang = 'hu';
  }
  store.setLanguage(store.chosenLang)


  const handleScroll = ()=> {
      //console.log('Scrolled!', mainContent.value.scrollTop);
      store.navbarHeight=mainContent.value.scrollTop > 800?'3em':'4em'
  }

  //SET DEFAULT HEADERS

  useHead({
        title: 'Poliol Kft.',
        meta: [
          {
            name: 'Poliol Kft.',
            content: 'Poliol Kft.',
          },
        ],
    });

  onMounted(() => {
    // Ensure the ref is available
    if (mainContent.value) {
      mainContent.value.addEventListener('scroll', handleScroll);
    }
    useHeaderSet();

  });

onBeforeUnmount(() => {
    // Clean up the event listener when the component is destroyed
    if (mainContent.value) {
      mainContent.value.removeEventListener('scroll', handleScroll);
    }
  });

  // Watch the route for changes and scroll to top
  watch(route, () => {
    mainContent.value.scrollTop = 0;
  });


</script>
<template>
    <div :style="store.sharedStyle" class="layout">
      <NavBar class="nav" :height="store.navbarHeight"/>
      <img class="absolute bot-0 z-40 right-0 h-48 w-48" src="/szechenyi_plus_eu.webp">

      <div class="main" :style="{marginTop:store.navbarHeight}" ref="mainContent">
        <slot/>
      </div>
      
    </div>
</template>
<style lang="scss">
  .layout {
    display: flex;
    flex-direction: column;
    height: 100dvh; /* 100% of the dynamic viewport height */
    width: 100dvw;
    margin: 0;
  }
  .nav{
    flex: 0 1 auto; /* Adjust the navbar height as needed */
    background-color: #333;
  }
  .main{
    margin-top: 8dvh;
    font-family: Inter,sans-serif;
    height: 100dvh;
    width: 100dvw;
    text-align: center;
    color: var(--dark-font-color);
    overflow: scroll;
    overflow-x: hidden;
    flex: 1 1 auto;
  }

</style>