<script setup>
  import { useWebStore } from '~/store/web';
  import { useAsyncData } from '#app';

  const store = useWebStore();
  const props = defineProps(['text', 'link']);

  const isChosen = computed(() => props.link === store.chosenTab);
  const showImage = computed(() => store.chosenTab !== undefined && isChosen.value);
</script>

<template>
  {{ chosenTab }}
  <div class="navBarItem" :class="{ chosen: isChosen}">
    <img src="/POLIOL_I_50x50.webp" v-if="showImage">
    <span class="">{{ text }}</span>
  </div>
</template>

<style scoped lang="scss">
.navBarItem{
  position: relative;
  font-family: Inter-Medium,sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  img{
    display: block;
    height: 1.3rem;
    margin-right: 6px;
  }
  &:hover{
    cursor: pointer;
  }
  &::after{
    content:'';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: #FF8700;
    display: none;
  }
  &:hover::after{
    display: block;
  }
  &.chosen{
    font-weight: bold;
    img{
      display: block;
    }
    &::after{
      display: block;
    }
  }
}
</style>